.splash-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -56px;
    /*margin-top: -28px;*/
    z-index: 99999;
    .splash-loader {
        width: 40px;
        height: 40px;
        position: relative;
        transform: rotateZ(45deg);

        >div {
            float: left;
            width: 50%;
            height: 50%;
            position: relative;
            transform: scale(1.1);

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #495057;
                animation: splash-loader-anim 2.4s infinite linear both;
                transform-origin: 100% 100%;
            }

            &.splash-cube2 {
                transform: scale(1.1) rotateZ(90deg);

                &:before {
                    animation-delay: 0.3s;
                }
            }

            &.splash-cube3 {
                transform: scale(1.1) rotateZ(180deg);

                &:before {
                    animation-delay: 0.6s;
                }
            }

            &.splash-cube4 {
                transform: scale(1.1) rotateZ(270deg);

                &:before {
                    animation-delay: 0.9s;
                }
            }
        }
    }
}

@keyframes splash-loader-anim {

    0%,
    10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}