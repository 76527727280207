.layout-topbar {
    height: 5rem;
    padding: 0 2rem 0 1rem;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    background-color: var(--v-body-bg);

    .app-logo {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        img {
            width: 3rem;
        }

        span {
            font-size: 2rem;
            font-weight: 700;
            margin-left: .5rem;
            color: var(--v-menuitem-text-color);
        }
    }

    .topbar-menubutton {
        position: relative;
        width: 3rem;
        height: 3rem;
        margin-left: 0px;
        border-radius: var(--border-radius);
        flex-shrink: 0;

        span {
            width: 2rem;
            height: 4px;
            display: block;
            position: absolute;
            top: 18px;
            left: 7px;
            border-radius: 7px;
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            transition-timing-function: swing;
            background-color: var(--v-menuitem-text-color-secondary);

            &:before {
                width: 1rem;
                height: 4px;
                content: "";
                position: absolute;
                right: 0;
                top: -9px;
                border-radius: 7px;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                transition-timing-function: swing;
                background-color: var(--v-menuitem-text-color-secondary);
            }

            &:after {
                width: 1rem;
                height: 4px;
                content: "";
                position: absolute;
                left: 0;
                top: 9px;
                border-radius: 7px;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                transition-timing-function: swing;
                background-color: var(--v-menuitem-text-color-secondary);
            }
        }

        &:hover {
            background-color: var(--v-menuitem-hover-bg);
        }
    }

    .topbar-menu {
        list-style-type: none;
        padding: 0;
        margin: 0 3rem;
        display: flex;
        gap: .5rem;
        flex: 1 1 auto;
        overflow: auto;

        > li {
            display: flex;
            align-items: center;
            transition: background-color var(--transition-duration);
            border-radius: var(--border-radius);
            padding: .5rem;
            cursor: pointer;

            a {
                font-size: 1.25rem;
                font-weight: 400;
                white-space: nowrap;
                padding-right: 1.375rem;
                color: var(--v-menuitem-text-color-secondary);
                opacity: .7;
                &.active-route {
                    opacity: 1;
                    color: var(--v-menuitem-text-color);
                }
            }

            i {
                color: var(--v-menuitem-text-color-secondary);
                font-size: .875rem;
                margin-left: .5rem;
                display: none;
            }

            &:hover {
                background-color: var(--v-menuitem-hover-bg);

                a {
                    padding-right: 0;
                }

                i {
                    display: block;
                }
            }

            &.topbar-menu-empty {
                color: var(--v-menuitem-text-color);
                cursor: auto;
                
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    .topbar-search {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        .topbar-searchbutton {
            background-color: var(--v-topbar-search-button-bg);
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: opacity .3s;
            transition-delay: .3s;
            cursor: pointer;

            i {
                color: var(--v-topbar-search-button-icon-color);
                font-size: 18px;
            }
        }

        .search-input-wrapper {
            position: relative;
            width: 0;
            opacity: 0;
            visibility: hidden;
            transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);

            span {
                width: 100%;

                .p-inputtext {
                    width: 100%;
                    position: relative;
                    border-radius: 40px;
                    padding: 9px;
                }

                i {
                    font-size: 18px;
                    margin-top: -9px;
                }
            }
        }

        &.topbar-search-active {
            .topbar-searchbutton {
                opacity: 0;
                pointer-events: none;
                cursor: default;
                transition: opacity .1s;
                transition-delay: .1s;
            }
            
            .search-input-wrapper {
                width: 190px;
                opacity: 1;
                visibility: visible;

                i {
                    display: block;
                }
            }
        }
    }

    .topbar-profile {
        border-radius: var(--border-radius);
        margin-left: 2rem;
        position: relative;
        flex-shrink: 0;

        .topbar-profile-button {
            display: flex;
            align-items: center;
            padding: .5rem;
            cursor: pointer;

            > img {
                width: 36px;
                margin-right: .55rem;
            }

            .profile-details {
                display: flex;
                flex-direction: column;
            }

            .profile-name {
                color: var(--v-menuitem-text-color);
                font-weight: 700;
            }

            .profile-job {
                color: var(--v-menuitem-text-color-secondary);
            }

            i {
                margin-left: .5rem;
                color: var(--v-menuitem-text-color-secondary);
                transition: transform var(--transition-duration);
            }
        }
    }
}
